.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--global-color-white);
}

.loadinglogo {
    width: 2rem;
    /* animation: spin 2s linear infinite; */
}